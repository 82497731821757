// Variables: includes Bootstrap overrides
@import "variables";
@import "functions";
@import "mixins";

// Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

* {
  font-family: "IBM Plex Sans", sans-serif !important;
}

:root {
  --dashboardCardHeight: 416px;
  --cardTitleHeight: 46px;
  --bs-success-rgb: 64, 187, 86;
}

// Tunga styles
html {
  font-size: $font-size-base; // 16px
}

body {
  font-size: $font-size-normal; // 14px
  padding: 0;
  width: 100%;
  min-height: 100%;
  background-color: get-color("light-grey");
}

html,
body {
  height: 100%;
}

b,
strong {
  font-weight: $font-weight-medium;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input[type=password]::-webkit-contacts-auto-fill-button,
input[type=password]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

.h-unset {
  height: unset !important;
}

.add-btn {
  color: #062e64;
  font-weight: 500;
  font-size: 16px;
  background-color: transparent;
  border: none;
}

.dashboard-card {
  padding: 24px;
  margin-bottom: 20px;
  border-width: 0;
  border-radius: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.11);
  background-color: #fff;
  border-radius: 6px;
  border: 0.5px solid rgba(0, 0, 0, 0.05);

  .title,
  .title>button>small {
    font-size: 18px;
    line-height: 22px;
    color: #151a30;
    font-weight: 500;
  }

  .title>button {
    background-color: transparent;
    border: none;
    line-height: unset;
    height: unset;
  }

  .content {
    max-height: calc(var(--dashboardCardHeight) - var(--cardTitleHeight));
    overflow-y: auto;
    margin-right: -12px;
    padding-right: 12px;

    /* width */
    &::-webkit-scrollbar {
      width: 4px;
      padding-left: 20px;
      margin-left: -10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: rgba(143, 155, 179, 0.15);
      box-shadow: 0px 4px 8px rgba(62, 72, 87, 0.04);
      border-radius: 4px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #8f9bb3;
      /* ShaOne */

      box-shadow: 0px 4px 8px rgba(62, 72, 87, 0.04);
      border-radius: 4px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #6b7588;
      cursor: pointer;
    }

    .list-layout {
      display: flex;
      flex-direction: row;
      padding: 24px 0;
      border-bottom: 1px solid #edf1f7;

      .icon-avatar {
        background: rgba(6, 46, 100, 0.1);
        width: 48px;
        height: 48px;
        border: 1px solid #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        flex-shrink: 0;

        span {
          margin: auto 0 auto 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #151a30;
        }
      }

      .detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;

        p,
        a,
        span {
          margin: 0;
        }

        p,
        a {
          margin-bottom: 4px;
          font-size: 16px;
          line-height: 22px;
        }

        p {
          color: #3e4857;

          a {
            font-weight: 500;
            color: #151a30;
            text-decoration: none;
          }
        }

        >span {
          display: flex;

          i {
            margin-right: 10px;
          }

          i:nth-child(2) {
            margin-left: 15px;
          }
        }

        .sub-text {
          font-size: 14px;
          line-height: 17px;
          color: #8f9bb3;
        }
      }
    }
  }
}

/*TODO: rename the css to make it more generic */
.project-planning,
.project-docs {
  .section {
    border-bottom: 1px solid #edf1f7;
    padding-bottom: 40px;
    margin-bottom: 40px;

    .section-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #151a30;
      }

      a {
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #062e64;

        i {
          vertical-align: baseline;
        }
      }
    }

    .section-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #ffffff;
      border: 1px solid #edf1f7;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 10px;

      a,
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #3e4857;

        i {
          vertical-align: baseline;
          margin-right: 20px;
          color: #8f9bb3;
        }
      }

      button {
        color: #8f9bb3;
      }
    }

    .section-item-wrapper {
      border: 1px solid #edf1f7;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 24px;

      .section-item {
        margin-bottom: 24px;

        .milestone-time {
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;

          span,
          i {
            color: #8f9bb3;
            vertical-align: middle;
          }

          button {
            margin-left: 26px;
          }
        }
      }

      .milestone-divider {
        position: relative;
        margin-left: -25px;
        margin-right: -25px;
        margin-bottom: 24px;

        .head {
          width: 0;
          height: 0;
          border-top: 7px solid transparent;
          border-left: 14px solid #da3451;
          border-bottom: 7px solid transparent;
          position: absolute;
          top: -6px;
        }

        .divider {
          background: #da3451;
          height: 2px;
          width: 100%;
        }

        span {
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          text-align: center;
          color: #3e4857;
          position: absolute;
          right: 0px;
          top: -12px;
          background: #f8f8f8;
          padding: 0 24px;
        }
      }
    }
  }

  .milestones {
    .btn-icon {
      height: auto;
      line-height: 1.5;
    }
  }

  .content-card>div {
    width: 600px;
    padding: 40px;
    margin: auto;
    background: white;
  }
}

.project-reports {
  table {
    background: #ffffff;
    border: 1px solid #dee2e6;
    box-sizing: border-box;
    border-radius: 6px;
    table-layout: fixed;
    border-collapse: initial;
    border-spacing: 0;
    overflow: hidden;

    thead tr {
      text-decoration: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #8f9bb3;
      background: rgba(62, 72, 87, 0.05);
      border-bottom: 1px solid #edf1f7;
      box-sizing: border-box;
      border-radius: 4px 4px 0px 0px;

      th {
        vertical-align: middle;
        padding: 10px 24px;
        border-bottom: 1px solid #edf1f7;
      }

      th:last-child {
        width: 60px;
        white-space: nowrap;
      }
    }

    tbody tr {
      font-size: 16px;
      line-height: 24px;
      text-transform: capitalize;
      color: #3e4857;
      border-bottom: 1px solid #edf1f7;

      td {
        vertical-align: middle;
        padding: 24px;
      }

      .avatar.avatar-dash {
        width: 35px;
        height: 35px;
      }

      .avatar.avatar-initials {
        font-size: 14px !important;
      }

      .btn-cta {
        color: #8f9bb3;
        text-decoration: none;
        padding: 2px;
      }

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        border-radius: 50px;
        padding: 6px 24px;

        &.pending {
          color: #8f9bb3;
          background: rgba(143, 155, 179, 0.05);
          border: 1px solid rgba(143, 155, 179, 0.04);
        }

        &.overdue {
          color: #eb5757;
          background: rgba(235, 87, 87, 0.04);
          border: 1px solid rgba(235, 87, 87, 0.04);
        }

        &.missed {
          color: #3e4857;
          background: rgba(62, 72, 87, 0.04);
          border: 1px solid rgba(62, 72, 87, 0.04);
        }

        &.completed {
          color: #219653;
          background: rgba(33, 150, 83, 0.04);
          border: 1px solid rgba(33, 150, 83, 0.04);
        }
      }
    }
  }
}

.bs-content-card {
  background-color: get-color("white");
  padding: 40px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.11);
  border: solid 1px #ececec;

  &.pending_invites {
    padding: 0px !important;
    max-width: 750px !important;
  }

  &.settings-card {
    max-width: 650px;
    background: #ffffff;
    border: 1px solid #edf1f7;
    border-radius: 6px;
    box-shadow: none;
    position: relative;
  }

  &.project-form-card {
    max-width: 920px;

    .form-group {
      padding-bottom: 20px;
    }
  }
}

.content-card>div {
  width: 100%;
  padding: 40px;
  margin: auto;
  background: white;
}

.content-card.settings-card {
  max-width: 900px;
  background: #fff;
  border: 1px solid #edf1f7;
  border-radius: 6px;
  box-shadow: none;
  position: relative;
}

.content-card.pending_invites {
  padding: 0 !important;
  max-width: 900px !important;
  >div:last-child {
    padding-top: 0px;
  }
}

.content-card.active_users {
  max-width: 100% !important;
}

.content-card.settings-card.full_width {
  max-width: 100% !important;
}

.infinite-scroll-component__outerdiv {
  padding-top: 0 !important;
}

.save {
  box-shadow: none;
  border: none;
  background: rgba(6, 46, 100, 0.05);
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #062e64;
  height: 2.5rem;
  padding: 0.4375rem 1.25rem;

  &:disabled {
    color: rgba(6, 46, 100, 0.25);
  }
}

// Buttons
.btn {
  font-size: $font-size-sm;
  font-weight: $font-weight-light;
  @include padding-y(0 !important);
  line-height: px-to-rem(40);
  height: px-to-rem(40);

  &-primary:not(:disabled):not(.disabled) {

    &.active,
    &:active {
      color: #fff;
      background-color: #b9223c;
      border-color: #ae2039;
    }
  }

  .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #b9223c;
    border-color: #ae2039;
  }

  &.btn-sm {
    line-height: px-to-rem(30);
    height: px-to-rem(30);
  }

  &.btn-lg {
    font-size: $font-size-normal;
    line-height: px-to-rem(50);
    height: px-to-rem(50);
  }

  &.btn-xl {
    font-size: $font-size-lg;
    line-height: px-to-rem(60);
    height: px-to-rem(60);
    border-radius: $border-radius-lg;
  }

  &.btn-xxl {
    font-size: $font-size-xl;
    line-height: px-to-rem(70);
    height: px-to-rem(70);
    border-radius: $border-radius-lg;
  }
}

// Tables
table {
  font-size: $font-size-sm;

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: get-color("lightest-grey");
  }

  &.table {
    margin-bottom: 0;

    th {
      font-weight: $font-weight-normal;
    }
  }

  .loading {
    text-align: left;
    margin: 0;

    >img {
      height: 15px !important;
    }
  }
}

.privacy-settings .section {
  margin-bottom: 20px;
}

.btn.animate {
  display: flex;
  align-items: center;
  fill: #fff;
}

.btn.animate svg {
  width: 30px;
  height: 30px;
}

.loader-opaque svg {
  fill: #062e64;
}

.toast-container {
  z-index: 10000;
  position: absolute;
  right: 30px;
  top: 32px;
}

.alert {
  font-size: 18px;
}


// React Widgets
.rw-widget {
  border-radius: 5px;
  box-shadow: $input-box-shadow;

  &.rw-state-focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 158, 255, 0.3) !important;
  }

  input {
    background-color: get-color("light-grey");
    height: 40px;

    &::placeholder {
      color: get-color("darker-grey");
    }
  }

  >.rw-select {
    background-color: get-color("light-grey");
    border-width: 0;
  }
}

.rw-datetimepicker {
  >.rw-select {
    border-radius: 0 5px 5px 0;
    -webkit-border-radius: 0 5px 5px 0;
    -moz-border-radius: 0 5px 5px 0;
    -ms-border-radius: 0 5px 5px 0;
    -o-border-radius: 0 5px 5px 0;
  }
}

.rw-calendar {
  margin: 10px auto 0;
  max-width: 500px;

  .rw-calendar-grid {
    th {
      text-align: center;
    }
  }

  .rw-header .rw-btn-view {
    background-color: get-color("light-grey");
  }

  .rw-btn,
  .rw-cell {

    &:hover,
    &:focus,
    &:active {
      color: $brand-color;
      background-color: get-color("white") !important;
      border-color: $brand-color;
    }

    &.rw-state-selected,
    &.rw-state-selected:hover,
    &.rw-state-selected:active,
    &.rw-state-selected:focus {
      color: get-color("white");
      background-color: $brand-color  !important;
      border-color: $brand-color;
    }
  }

  .rw-empty-cell {
    height: 36px;
    background-color: get-color("white");
  }
}

// Modals
.modal-dialog {
  .modal-body {

    input,
    textarea {
      border: 1px solid rgba(194, 204, 217, 0.25) !important;
      background: none !important;
      box-shadow: none !important;
    }

    .rw-widget {
      box-shadow: none !important;
    }

    .input-group {
      box-shadow: none !important;
    }

    .border-zero {
      border: 0 !important;
      outline: none !important;
      width: 36px;
      color: #062E64;
    }
  }
}

.modal-dialog.consent {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -16%) !important;
  min-height: 0;
  width: 420px;

  .modal-header {
    padding-top: 15px !important;
    padding-left: 15px;
    padding-right: 15px;

    .modal-title {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .modal-body {
    padding: 15px;
    max-height: calc(100vh - 550px);
    overflow-y: auto;
  }
}

@media only screen and (max-width: 425px) {
  .modal-dialog.consent {
    width: 85%;
    transform: translate(-50%, -20%) !important;

    .modal-body {
      max-height: calc(100vh - 500px);
    }
  }
}

.modal-dialog.modal-alert.alert-dailogue,
.modal-dialog.modal-alert.error-dailogue {
  min-height: auto;
  max-width: 600px;
  top: 16px;

  .modal-content {
    box-shadow: none;

    .modal-body {
      padding: 11px 20px;
      line-height: 21px;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.modal-dialog.modal-alert.alert-dailogue {
  .modal-content {
    background: #f2faf9;
    border: 1px solid #219653;

    .modal-body {
      color: #219653;
    }
  }
}

.modal-dialog.modal-alert.error-dailogue {
  .modal-content {
    background: #faf2f2;
    border: 1px solid #962121;

    .modal-body {
      color: #962121;
    }
  }
}

.modal-dialog.modal-payments {
  .modal-content {
    box-shadow: none;
    border-radius: 6px;
  }

  &.modal-large {
      max-width: 800px !important;
  }
  
  &.modal-xlarge {
      max-width: 950px !important;
  }
}

.modal-dialog.modal-report.modal-survey {
  max-width: 450px !important;
}

.modal-dialog.modal-report.modal-make-payment {
  max-width: 550px !important;

  .modal-body {
    padding-top: 0;
  }
}

.modal-dialog.modal-tcs {
  max-width: 800px;

  .modal-body {
    overflow-y: scroll;
    height: 80vh;

    /* width */
    &::-webkit-scrollbar {
      width: 14px;
      border: 10px solid rgba(0, 0, 0, 0);
      border-top: none;
      border-left: none;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: rgba(143, 155, 179, 0.15);
      box-shadow: 0px 4px 8px rgba(62, 72, 87, 0.04);
      background-clip: padding-box;
      border: 10px solid rgba(0, 0, 0, 0);
      border-top: none;
      border-left: none;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border: 10px solid rgba(0, 0, 0, 0);
      border-top: none;
      border-left: none;
      background-clip: padding-box;
      background-color: #8f9bb3;
      box-shadow: -1px -1px 0px rgba(62, 72, 87, 0.05);
      -webkit-box-shadow: inset -1px -1px 0px rgba(62, 72, 87, 0.05),
        inset 1px 1px 0px rgba(62, 72, 87, 0.05);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      border: 10px solid rgba(0, 0, 0, 0);
      border-top: none;
      border-left: none;
      background-clip: padding-box;
      background-color: #6b7588;
      cursor: pointer;
    }
  }
}

.modal-dialog.modal-report {
  max-width: 800px;

  .modal-content {
    box-shadow: none;
    border-radius: 6px;
  }

  .modal-body {
    padding-top: 25px;
    padding-bottom: 25px;
    max-height: 35rem;
    overflow: auto;
    padding-right: 22px;

    &:hover::-webkit-scrollbar {
      width: 4px;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 0px;
      padding-left: 20px;
      margin-left: -10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: rgba(143, 155, 179, 0.15);
      box-shadow: 0px 4px 8px rgba(62, 72, 87, 0.04);
      border-radius: 4px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #8f9bb3;
      box-shadow: 0px 4px 8px rgba(62, 72, 87, 0.04);
      border-radius: 4px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #6b7588;
      cursor: pointer;
    }
  }
}

.modal-dialog.share-profile-modal .modal-content > div > *:first-child {
  padding-bottom: 15px
}

.modal-dialog.request-technical-test-modal>div>div>div {
  padding-bottom: 12px;
}

.modal-dialog.request-technical-test-modal {
  min-width: 728px;
}

.modal-dialog {
  display: flex;
  align-items: center;
  min-height: 100vh;
  margin: 0 auto;

  &.modal-sm {
    max-width: 360px !important;
  }

  &.modal-md {
    max-width: 540px !important;
  }

  &.modal-alert,
  &.modal-confirm,
  &.modal-prompt {
    max-width: 360px;
  }

  &.modal-pay {
    max-width: 450px;
  }

  .modal-content {
    border-radius: 4px;

    >div>* {
      @include padding-x(24px);

      &:first-child {
        padding-top: 24px;
        align-items: center;
      }

      &:last-child:not(.modal-footer) {
        padding-bottom: 40px;
      }
    }
  }

  .modal-header {
    padding-bottom: 0;
    border-bottom-width: 0;

    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      color: get-color("black");
      line-height: 1.5;
      height: auto;
      padding: 0;
      margin: 0;
    }
  }

  .modal-footer {
    border-top-width: 0;
  }

  .modal-body {
    padding-left: 0;
    padding-right: 0;
  }

  &.consent {
    .modal-content>div {
      padding: 15px;
    }

    .modal-body {
      padding-left: 0;
      padding-right: 0;
    }
  }

}

.bsd-mb10 {
  margin-bottom: -7px !important;
}

.bsd-mb12 {
  margin-bottom: -12px !important;
}

.bsd-mb6 {
  margin-bottom: -6px !important;
}

.bsd-resend-inv {
  float: right !important;
}

.bs-x-danger {
  color: #962121 !important;
}

.bsd-icon {
  margin-left: 5px;
  vertical-align: middle !important;
}

#table-dropdown .dropdown-menu {
  padding: 15px 0;

  .dropdown-item {
    padding: 15px 24px;
    color: #3e4857;

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    i {
      color: #8f9bb3;
    }

    &:active {
      color: initial;
      background-color: initial;
    }
  }
}

.company-settings {
  border: none !important;
}

.bsd-icon-ext {
  background: none !important;
  right: 55px !important;
}

.exp-input {
  border: none !important;
  outline: none !important;
}

.logout-icon,
.tg-ic-outline-settings {
  font-size: 1.4em;
  vertical-align: middle;
}

.custom-radio-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;

  .radio-button {
    margin-right: 16px;

    &:last-of-type {
      margin-right: 0px;
    }
  }
}

.custom-radio-group input[type="radio"] {
  position: absolute;
  left: -9999px;
}

.custom-radio-group input[type="radio"]+label {
  position: relative;
  padding: 0 0 0 30px;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  color: #151a30;
  font-weight: 300;
  min-width: 128px;
}

.custom-radio-group input[type="radio"]+label:before {
  content: "";
  background: #fff;
  border: 2px solid #151a30;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 0;
}

.custom-radio-group input[type="radio"]+label:after {
  content: "";
  background: #151a30;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  left: 5px;
  opacity: 0;
  transform: scale(2);
  transition: transform 0.3s linear, opacity 0.3s linear;
}

.custom-radio-group input[type="radio"]:checked+label:after {
  opacity: 1;
  transform: scale(1);
}

.overlay-inner-star .tooltip-inner{
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 50px;
  padding: 4px 10px;
}

.s-payment {
  &, &>div:not(.info):not(.payout-list) {
    background: unset !important;
    border: none !important;
    max-width: 1024px !important;
  }
}