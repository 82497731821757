// skill library css
.skills_library,
.skills_suggestions {
    max-width: 100% !important;
    width: 100% !important;
    background: #fff;
    border-radius: 6px;
    box-shadow: none;
    position: relative;

    tr>td {
        color: #151A30 !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
    }

    #skill-filter>div {
        border-radius: 4px;
        color: #151A30 !important;
        font-weight: 700 !important;
        border: 1px solid #E3E9F2 !important;
        background: #FFF !important;
        box-shadow: none !important;
    }

    .table {
        border: none !important;
    }

    thead tr {
        border-radius: 0px !important;
    }

    .skills_footer .flex-items {
        border-radius: 0px 0px 4px 4px;
        background: #F5F7FA !important;
    }

    .skills_footer select {
        color: var(--gray-01, #151A30);
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        border: none !important;
        background-color: transparent !important;
        background: transparent !important;
    }

    .skills_footer svg {
        width: 15px !important;
        height: 15px !important;
    }

    #newSkill {
        color: rgb(255, 255, 255);
        font-weight: 500;
        text-align: center;
        background: rgb(218, 52, 81);
        border-color: transparent;
        box-shadow: none;
        border-bottom-right-radius: 4px !important;
        border-top-right-radius: 4px !important;

    }

    .input-group i {
        color: #7F8C9F !important;
        font-size: 11pt !important;
        font-weight: 100 !important;
    }

    .actions-icons button {
        border: none !important;
        font-size: 16px !important;
        outline: none !important;
        box-shadow: none !important;
    }

    .suggested-skills-header {
        color: var(--grey-10, #22262A);
        font-family: IBM Plex Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .hidden {
        display: none;
    }


    .actions-icons>.add-btn:hover::after {
        content: attr(data-tooltip);
        position: absolute;
        border-radius: 50px;
        background: var(--grey-10, #22262A);
        padding: 5px 10px;
        white-space: nowrap;
        font-size: 14px;
        color: var(--white, #FFF);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        margin-top: 30px;
        transform: translateX(-50%);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    }

    .actions-icons>.add-btn:hover::after {
        opacity: 1;
        visibility: visible;
    }

    .actions-icons>.reject-btn:hover::after {
        content: attr(data-tooltip);
        position: absolute;
        border-radius: 50px;
        background: var(--grey-10, #22262A);
        padding: 5px 10px;
        white-space: nowrap;
        font-size: 14px;
        color: var(--white, #FFF);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        margin-top: 5px;
        margin-left: 20px;
        transform: translateX(-50%);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    }

    .actions-icons>.reject-btn:hover::after {
        opacity: 1;
        visibility: visible;
    }



}

#skill-type,
#skill-reject-reason,
textarea#other_reason {
    border-radius: 4px;
    border: 1px solid var(--grey-02, #E0E1E2);
    background: var(--white, #FFF);
    box-shadow: none !important;
}

#add-skill-form {
    label {
        color: var(--grey-10, #22262A);
        font-weight: 500 !important;
        line-height: 150%;
    }

    #skill_name {
        padding: 0.4375rem 1.25rem !important;
    }
}

.modal-skills {
    .modal-content>div>*:first-child {
        padding-bottom: 20px !important;
    }
}

#skill-reject-reason ,
textarea#other_reason {
    color: var(--gray-03, #7F8C9F);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

select#skill-type,
#skill_name,
#skill_name::placeholder {
    color: #1A1A1A !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

#skill_name::placeholder {
    color: #7F8C9F !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}